<template>
  <Poster />
  <br />
  <PopularMovies />
  <Upcoming />
  <TopAnime />
  <Doughva />
  <div class="p-8 max-w-6xl mx-auto">
    
  </div>
</template>

<script>
import Poster from "@/components/Poster.vue";
import PopularMovies from "@/components/PopularMovies.vue";
import TopAnime from "@/components/TopAnime.vue";
import Upcoming from "@/components/Upcoming.vue";
import Doughva from "@/components/Doughva.vue";
import { setMetaTags } from '@/metadata';

export default {
  name: 'HomeView',
  async mounted() {
    setMetaTags({
      title: `${ this.metadata.title } - A ${ this.metadata.shortTitle.toLowerCase() } one to find your movies`,
      description: 'We use a bit of AI and opensoure api to give you the best related results with your intrest.',
    });
  },
  components: {
    Poster,
    PopularMovies,
    TopAnime,
    Upcoming,
    Doughva
  }
}
</script>
