export function setMetaTags({ title, description }) {
  document.title = title;

  // Update or add meta description tag
  const existingMetaTag = document.querySelector('meta[name="description"]');
  if (existingMetaTag) {
    existingMetaTag.setAttribute('content', description);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'description';
    newMetaTag.content = description;
    document.head.appendChild(newMetaTag);
  }
}
