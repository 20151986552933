<template>
  <div v-if="loading && !vertical" class="max-w-6xl mx-auto overflow-x-auto whitespace-nowrap text-white no-scrollbar z-0 relative">
    <div v-for="index of 7" :key="index" class="inline-block mr-4 animate-pulse bg-gradient-to-b from-gray-700 via-gray-900 to-gray-800 h-60 w-40 mt-4 rounded"></div>
  </div>
  <div v-if="loading && vertical" class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
    <div v-for="index in 12" class="mb-4">
      <div :key="index" class="inline-block mr-4 animate-pulse bg-gradient-to-b from-gray-700 via-gray-900 to-gray-800 h-60 w-full mt-4 rounded"></div>
      <div :key="index" class="inline-block mr-4 animate-pulse bg-gray-700 h-4 w-full mt-4 rounded"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
