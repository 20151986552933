import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MovieDetail from '../views/Movie.vue';
import CastDetail from '../views/Cast.vue';
import RelatedMovie from '../views/RelatedMovie.vue';
import RelatedShows from '../views/RelatedShows.vue';
import NotFound from '../views/404.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/us',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/player',
    name: 'player',
    component: () => import('../views/Video.vue')
  },
  {
    path: '/genre/:genre',
    name: 'genre',
    component: () => import('@/views/GenrePage.vue'),
    props: true,
  },
  {
    path: '/company/:companyId',
    name: 'company',
    component: () => import('@/views/Company.vue'),
    props: true,
  },
  {
    path: '/actor/:actorId',
    name: 'actor',
    component: () => import('@/views/Actor.vue'),
    props: true,
  },
  {
    path: '/:media/:movie',
    name: 'movieDetail',
    component: MovieDetail,
    props: true
  },
  {
    path: '/:media/:movie/cast',
    name: 'CastDetail',
    component: CastDetail,
    props: true
  },
  {
    path: '/watchnow/movie/:movie',
    name: 'RelatedMovie',
    component: RelatedMovie,
    props: true
  },
  {
    path: '/watchnow/show/:movie',
    name: 'RelatedShows',
    component: RelatedShows,
    props: true
  },
  {
    path: '/:media/:movie/clips',
    name: 'Clips',
    component: () => import('../views/Clips.vue'),
    props: true
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/watchnow',
    name: 'WatchNow',
    component: () => import('../views/WatchNow.vue')
  },
  {
    path: '/us/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/us/cookies',
    name: 'Cookies',
    component: () => import('../views/Cookies.vue')
  },
  {
    path: '/us/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
