<template>
  <div class="bg-gray-900 p-4 rounded-md flex items-center justify-center my-4">
    <div ref="target" class="overflow-hidden rounded-md"></div>
  </div>
</template>

<script>
export default {
  props: {
    settings: Object,
  },
  mounted() {
    this.createScript();
  },
  methods: {
    createScript() {
      // Create script element
      const script = document.createElement('script');

      // Set script settings
      script.settings = this.settings || {};

      // Set script source URL
      script.src = "//pleasedexample.com/bPXLV/ssd.GTly0fY/WfdqivYxWR5/uyZeXtIz/Yevmg9CuJZgUWl/kQPaT/UEx/MGDGgJ5INej/A/tNNuTOEmwsOQD/k/2/MhQq";

      // Set script to load asynchronously
      script.async = true;

      // Clear the content of the target element
      this.$refs.target.innerHTML = "";

      // Append the script to the target element
      this.$refs.target.appendChild(script);
    },
  },
};
</script>