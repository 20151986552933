<template>
  <div v-if="!loading" class="font-poppins text-white rounded-lg">
    <div v-if="!!data.backdrop_path" class="absolute top-0 left-0 w-full">
      <img :src="`https://image.tmdb.org/t/p/original${data.backdrop_path}`" class="w-full object-cover opacity-70" alt="poster">
      <div class="absolute w-full h-full top-0 left-0 bg-gradient-to-b from-transparent via-black-80 to-black pt-12 md:p-24">
      </div>
    </div>
    <div class="pt-12 md:pt-24 z-50">
      <div class="max-w-4xl mx-auto pt-24 p-8 md:p-4 backdrop-blur-none">
        <br /><br />

        <div class="cast pt-6">
          <h3 class="font-semibold my-6 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Cast</h3>
          <div v-if="filteredCast" class="grid grid-cols-2 md:grid-cols-3 gap-4 text-black">
            <div v-for="show in filteredCast" :key="show.id"  class="mr-4 bg-gray-900/50 hover:bg-gray-700/50 backdrop-blur-md border-gray-500 border-2 ease-in ease-out duration-300 p-4 px-8 rounded-xl w-40 inline-flex w-full min-h-full items-center justify-center ">
              <a :href="`/actor/` + show.id">
                <img
                  :src="`https://image.tmdb.org/t/p/w200${show.profile_path}`"
                  :alt="show.name || show.title"
                  class="w-24 h-24 object-cover rounded-full mx-auto cursor-pointer opacity-90 hover:opacity-70 transition border-4 border-gray-500"
                />
                <div class="px-2 whitespace-normal max-w-full overflow-hidden text-center">
                  <h2 class="text-md font-semibold mt-2 text-white max-w-[100%]">{{ show.name.length > 25 ? show.name.substring(0, 22) + "..." : show.name }}</h2>
                  <p class="truncate text-gray-400">{{ show.character.length > 25 ? show.character.substring(0, 23) + ".." : show.character  }}</p>
                </div>
              </a>
            </div>
          </div>
          <div v-else>
            <p>No Cast</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex items-center justify-center h-screen">
    <div class="inline-flex animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
  </div>
</template>

<script>
import API_KEY from '@/env';
import axios from 'axios';
import { setMetaTags } from '@/metadata';

export default {
  props: ['movie'],
  data() {
    return {
      data: {},
      loading: true,
      filteredCast: [],
    };
  },
  async mounted() {
    try {
      const mediaType = window.location.pathname.includes('/movie') ? 'movie' : 'tv';

      const response = await axios.get(
        `https://api.themoviedb.org/3/${mediaType}/${this.movie}?append_to_response=credits`,
        {
          params: {
            api_key: API_KEY,
          },
        }
      );


      this.data = response.data;

      setMetaTags({
        title: `${this.data.title || this.data.name} - Cast`,
        description: this.data.overview || 'No description available',
      });

      this.filteredCast = this.data.credits.cast.filter(castMember => castMember.profile_path);
    } catch (error) {
      console.error('Error fetching movie details:', error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
/* Add custom styling here if needed */
</style>
