<template>
  <div class="p-8 max-w-6xl mx-auto text-white">
    <h1 class="text-2xl font-bold">Upcoming Movies</h1>
  </div>
  <Loader :loading="loading"/>
  <div v-if="movies && !loading" class="relative max-w-6xl mx-auto text-white group">
    <div @click="scrollMovies(-1)" class="absolute top-0 left-0 z-50 h-full text-2xl bg-gradient-to-r from-black/70 to-transparent pt-24">
      <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&lt;</span>
    </div>
    <div @click="scrollMovies(1)" class="absolute top-0 right-0 z-50 h-full text-2xl bg-gradient-to-l from-black/70 to-transparent inline-block pt-24">
      <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&gt;</span>
    </div>
    <div class="upcoming relative overflow-x-auto whitespace-nowrap text-white no-scrollbar z-0">
      <div v-for="(movie, index) in movies" :key="movie.id" class="inline-block mr-4 relative group">
        <a :href="`/movie/` + movie.id">
          <img
            :src="getImageUrl(movie.poster_path)"
            :alt="movie.title"
            class="w-40 h-60 object-cover rounded-lg cursor-pointer opacity-90 hover:opacity-70 transition"
            @click="redirectToMovieDetails(movie.id)"
          />
          <div class="invisible md:group-hover:visible flex items-end absolute p-4 top-0 left-0 z-0 w-full h-full bg-gradient-to-t from-black/80 to-transparent">
            <h2 class="whitespace-normal text-lg font-bold text-white">
              {{ movie.title.length > 35 ? movie.title.substring(0, 35) + "..." : movie.title }}
            </h2>
          </div>
          <div class="px-2 whitespace-normal max-w-full overflow-hidden md:hidden">
            <h2 class="text-lg font-semibold mt-2 text-white truncate max-w-[100%]">{{ movie.title.length > 12 ? movie.title.substring(0, 12) + "..." : movie.title }}</h2>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_KEY from '@/env';
import Loader from "./Loader.vue"

export default {
  components: {
    Loader
  },
  data() {
    return {
      movies: [],
      apiKey: API_KEY,
      scrollStep: 200,
      loading: true,
    };
  },
  mounted() {
    this.fetchUpcomingMovies();

    this.loading = false;
  },
  methods: {
    async fetchUpcomingMovies() {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/upcoming?api_key=${this.apiKey}`
        );

        if (response.data && response.data.results) {
          this.movies = response.data.results.slice(0, 10);
        }
      } catch (error) {
        console.error("Error fetching upcoming movies:", error);
      }
    },
    getImageUrl(posterPath) {
      const baseUrl = "https://image.tmdb.org/t/p/w200";
      return posterPath ? `${baseUrl}${posterPath}` : "";
    },
    redirectToMovieDetails(movieId) {
      console.log(`Redirect to movie details for ID: ${movieId}`);
      // Implement navigation to movie details page or other actions
    },
    scrollMovies(direction) {
      const container = document.querySelector('.upcoming');
      const scrollAmount = this.scrollStep * direction;
      container.scrollLeft += scrollAmount;
    },
  },
};
</script>