<template>
  <div class="relative h-[90vh] z-0 mb-8">
    <img :src="poster.img" class="w-full h-[90vh] object-cover" alt="TV Series Poster"/>

    <div class="bg-gradient-to-b from-black/20 via-black/20 via-80% to-black to-99% absolute top-0 left-0 w-full h-[90vh]">
      <div class="h-[90vh] flex flex-col items-start justify-end p-8 xl:px-0 text-white max-w-6xl mx-auto">
        <span class="bg-white/40 inline-block backdrop-blur-lg rounded-full border border-white border-2 p-1 px-2 text-sm">{{ poster.catogery }}</span>
        <h2 class="block text-2xl md:text-4xl text-white font-extrabold mt-2">{{ poster.name }}</h2>
        <p class="max-w-xl mt-2">{{  poster.overview.length < 200 ? poster.overview : poster.overview.slice(0, 200) + "..." }}</p>
        <a class="rounded-md bg-white text-black p-2 px-4 font-semibold mt-4" :href="poster.link">See More</a>
      </div>
    </div>
  </div>
</template>

<script>
const poster = [
  {
    img: "https://image.tmdb.org/t/p/original/d3mjRLGl6AsghMrc5taOXuN80iQ.jpg",
    overview: "On the Earth, a catastrophe triggered the variation of all kinds of species. The superior survived and the inferior were extinct. Under this circumstance, Luo Feng inherited from the owner of Yunmo Star and became one of the three strongest people on the Earth. He lost his flesh during the fight against giant swallowed monster but then he took the flesh of the monster. In the flesh, he developed a human body. Later, he stepped out of the Earth and headed to the universe.",
    name: 'Swallowed Star',
    id: 101172,
    link: '/show/101172',
    catogery: 'Animation'
  },
  {
    img: "https://image.tmdb.org/t/p/original/fm6KqXpk3M2HVveHwCrBSSBaO0V.jpg",
    overview: "The story of J. Robert Oppenheimer's role in the development of the atomic bomb during World War II.",
    name: 'Oppenheimer',
    id: 872585,
    link: '/movie/872585',
    catogery: 'Drama'
  },
  {
    img: "https://image.tmdb.org/t/p/original/mDfJG3LC3Dqb67AZ52x3Z0jU0uB.jpg",
    overview: "As the Avengers and their allies have continued to protect the world from threats too large for any one hero to handle, a new danger has emerged from the cosmic shadows: Thanos. A despot of intergalactic infamy, his goal is to collect all six Infinity Stones, artifacts of unimaginable power, and use them to inflict his twisted will on all of reality. Everything the Avengers have fought for has led up to this moment - the fate of Earth and existence itself has never been more uncertain.",
    name: 'Avengers: Infinity War',
    id: 299536,
    link: '/movie/299536',
    catogery: 'Adventure'
  },
  {
    img: "https://image.tmdb.org/t/p/original/kjQBrc00fB2RjHZB3PGR4w9ibpz.jpg",
    overview: "Amid a future war between the human race and the forces of artificial intelligence, a hardened ex-special forces agent grieving the disappearance of his wife, is recruited to hunt down and kill the Creator, the elusive architect of advanced AI who has developed a mysterious weapon with the power to end the war—and mankind itself.",
    name: 'The Creator',
    id: 670292,
    link: '/movie/670292',
    catogery: 'Science Fiction'
  },
];

export default {
  data() {
    return {
      poster: poster[0],
    };
  },
  mounted() {
    this.pickRandomPoster();
  },
  methods: {
    pickRandomPoster() {
      const randomIndex = Math.floor(Math.random() * poster.length);
      this.poster = poster[randomIndex];
    },
  },
};
</script>
