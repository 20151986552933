<template>
  <div>
    <button v-if="loader" class="animate-pulse bg-gray-300 h-8 w-24 rounded"></button>
    <div v-if="showData">
      <a v-for="episode in showData" :href="JSON.parse(episode.data).dLink" target=”_blank” :key="episode.id" class="episode-container inline-block mr-1 p-1 px-4 bg-white/30 rounded-md ">
        <h3>{{ episode.episode }}</h3>
      </a>
    </div>
  </div>
</template>

<script>
import Trailor from "@/components/Trailor.vue";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://hcjvlwoloplljaoeikih.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhjanZsd29sb3BsbGphb2Vpa2loIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQxMTUyNTAsImV4cCI6MjAxOTY5MTI1MH0.de6v9sNKkoKC0l3ldgUzWBBMs2bFuPLAO6hRgEO-OXE');

export default {
  components: {
    Trailor
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showData: null,
      loader: true,
    };
  },
  async mounted() {
    if (this.type === 'tv') {
      // Assuming you have set up Supabase client
      const { data, error } = await supabase
        .from('shows')
        .select('*')
        .eq('show_id', this.id)

      if (error) {
        console.error('Error fetching movie data:', error.message);
      } else {
        this.showData = data;
        console.log(typeof this.showData)
      }

      this.loader = false;
    }
  },
};
</script>

<style scoped>
/* Add your component styles here if needed */
</style>
