<template>
  <div class="bg-black min-h-screen text-gray-600">
    <Navbar msg="hello" />
    <router-view class="min-h-screen relative"/>
    <Footer />
    
    <!-- Cookie Popup -->
    <div v-if="!isCookieAccepted" class="fixed bottom-0 left-0 right-0 bg-white/10 text-white p-4 m-4 md:m-8 rounded-xl backdrop-blur-md border border-white z-50">
      <p>This website uses cookies. By continuing to use this site, you accept our use of cookies. <button @click="acceptCookie" class="md:ml-4 bg-blue-600 text-white px-2 py-1 rounded block w-full md:inline-block md:w-auto mt-4 md:mt-0">Accept</button></p>
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import { onMounted } from 'vue';
import { initFlowbite } from 'flowbite';

// initialize components based on data attribute selectors
onMounted(() => {
    initFlowbite();
});

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      isCookieAccepted: false,
    };
  },
  methods: {
    acceptCookie() {
      this.isCookieAccepted = true;
      localStorage.setItem('cookieAccepted', 'true');
    },
  },
  mounted() {
    this.isCookieAccepted = localStorage.getItem('cookieAccepted') === 'true';
  },
};
</script>
