<!-- TrailerPopup.vue -->

<template>
  <div>
    <button v-if="trailerLoader" class="animate-pulse bg-gray-300 h-8 w-24 rounded"></button>
    <button v-if="!trailerLoader && videos.length > 0" @click="openTrailerPopup" class="backdrop-blur-xl bg-white/10 border border-white text-white py-2 px-4 rounded-lg flex items-center">
      <i class='bx bx-play mr-2 text-purple-500'></i> {{ mediaType === 'movie' ? 'Watch Trailer' : 'Watch Clip' }}
    </button>

    <div v-if="showTrailerPopup" class="fixed top-0 left-0 p-4 w-full h-full flex items-center justify-center bg-black bg-opacity-75" style="z-index: 991;">
      <button @click="closeTrailerPopup" class="absolute top-4 md:top-10 right-4 md:right-10 text-white text-2xl cursor-pointer">&times;</button>
      <div class="w-full max-w-6xl mx-auto p-2 bg-white/10 rounded-lg relative">
        <div v-if="!trailerLoader" v-for="(video, index) in videos.slice(0, 1)" :key="index" class="relative w-full overflow-hidden rounded-xl">
          <iframe :src="getYouTubeEmbedUrl(video.id.videoId)" class="w-full h-[60vh] max-h-[1080px]" :class="videoLoader ? 'hidden' : 'block'" frameborder="0" allowfullscreen @load="handleVideoLoad"></iframe>
          <div :class="!videoLoader ? 'hidden' : 'block'" class="animate-pulse bg-gray-300 h-[60vh] max-h-[1080px] rounded-xl"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import API_KEY from '@/env';

export default {
  props: {
    movieId: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      videos: [],
      showTrailerPopup: false,
      trailerLoader: true,
      videoLoader: true,
    };
  },
  async mounted() {
    try {
      const videoResponses = await this.searchTMDbVideos(this.movieId, this.mediaType);

      this.videos = videoResponses.data.results;
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      this.trailerLoader = false;
    }
  },
  computed: {
    trailers() {
      return this.videos ? this.videos.filter(video => video.title.contains('Trailer')) : [];
    },
    hasVideos() {
      return this.videos && this.videos.length > 0;
    },
  },
  methods: {
    async searchTMDbVideos(id, type) {
      try {
        const videosResponse = await axios.get(
          `https://api.themoviedb.org/3/${type}/${id}/videos`,
          {
            params: {
              api_key: API_KEY,
            },
          }
        );

        return videosResponse;
      } catch (error) {
        console.error('Error searching TMDb videos:', error);
        throw error;
      }
    },

    getYouTubeEmbedUrl(videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    },

    openTrailerPopup() {
      this.showTrailerPopup = true;
    },

    closeTrailerPopup() {
      this.showTrailerPopup = false;
    },

    handleVideoLoad() {
      this.videoLoader = false;
    },
  },
};
</script>
