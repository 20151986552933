<template>
  <div class="navbar text-gray-300 px-6 py-4 fixed z-50 w-full bg-gradient-to-b from-black/90 via-black/60 via-60% to-transparent" :class="{ 'backdrop-blur-md bg-black/30 border-b border-b-gray-600': isScrolled }" style="z-index: 990;">
    <div class="flex max-w-6xl mx-auto">
      <a href='/' class="order-1 flex items-center">
        <img src="@/assets/logo.png" alt="logo" class="logo h-6">
        <h1 class="ml-2 text-2xl font-bold flex items-center">
          <span class="font-light text-gray-600 text-lg italic mt-[2px]">/</span>
          <span class="flex items-end text-white">
            <span class="ml-3">{{ this.metadata.shortTitle.toUpperCase() }}</span>
            <span class="h-6 text-sm pt-[2px] pl-1 hidden md:inline-flex ">{{ this.metadata.navSubTitle.toUpperCase() }}</span>
          </span>
        </h1>
      </a>
      <div class="order-2 flex-grow flex items-center justify-end gap-4">
        <div class="hidden md:flex flex-grow items-center justify-start gap-4 px-8 ">
          <a href='/us' class="text-gray-400 hover:text-white font-normal text-sm ease-in ease-out duration-600">Story</a>
          <a href='/us/terms' class="text-gray-400 hover:text-white font-normal text-sm ease-in ease-out duration-600">Terms</a>
          <a href='/us/privacy' class="text-gray-400 hover:text-white font-normal text-sm ease-in ease-out duration-600">Privacy</a>
        </div>
        <a href='/search' class="flex items-center" v-if="!isSearchRoute">
          <i class='bx bx-search text-white text-2xl'></i>
        </a>
        <div class="hidden sm:flex items-center" v-if="!isWatchNowRoute">
          <a href='/watchnow' type="button" class="text-black bg-white font-medium rounded-md text-sm px-3 py-2 text-center">WatchNow</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isScrolled: false
    };
  },
  computed: {
    isSearchRoute() {
      return this.$route.path === '/search';
    },
    isWatchNowRoute() {
      return this.$route.path === '/watchnow';
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route(to, from) {
      // Update the isScrolled variable when the route changes
      this.isScrolled = window.scrollY > 0;
    }
  },
  methods: {
    handleScroll() {
      // Check the scroll position and update the isScrolled variable accordingly
      this.isScrolled = window.scrollY > 0;
    }
  }
};
</script>