<template>
  <footer class="bg-black">
    <div class="mx-auto w-full max-w-6xl p-4 py-6 lg:py-8">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0">
          <a href="/" class="flex items-center">
            <img src="@/assets/logo.png" class="h-8 me-3" alt="{{ this.metadata.shortTitle }} Logo" />
            <span class="self-center text-2xl font-semibold whitespace-nowrap text-white">{{ this.metadata.shortTitle }}</span>
          </a>
        </div>
        <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Resources</h2>
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <a href="/us" class="hover:underline">About US</a>
              </li>
              <li>
                <a href="/watchnow" class="hover:underline">Watch Now</a>
              </li>
            </ul>
          </div>
          <div>
            <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Legal</h2>
            <ul class="text-gray-500 dark:text-gray-400 font-medium">
              <li class="mb-4">
                <a href="/us/privacy" class="hover:underline">Privacy Policy</a>
              </li>
              <li class="mb-4">
                <a href="/us/terms" class="hover:underline">Terms of use</a>
              </li>
              <li>
                <a href="/us/cookies" class="hover:underline">Cookie policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sm:flex sm:items-center sm:justify-between bg-gray-900 p-4 px-8 rounded-xl mt-8">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          {{ this.metadata.copyright }}
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  // Add any necessary logic or data here
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
