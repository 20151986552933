<template>
  <div class="bg-black h-screen flex items-center justify-center text-white">
    <div class="text-center">
      <h1 class="text-6xl font-bold mb-4">404</h1>
      <p class="text-lg mb-8">Oops! The page you are looking for doesn't exist.</p>
      <a href="/" class="bg-purple-500 text-black px-4 py-2 rounded-full font-bold text-lg hover:bg-orange-600">Go back to the homepage</a>
    </div>
  </div>
</template>

<script>
import { setMetaTags } from '@/metadata';

export default {
  async created() {
    setMetaTags({
      title: `404 - Website Not Found`,
    });
  }
}
</script>
