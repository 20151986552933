import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/styles/main.css";
import 'boxicons/css/boxicons.min.css';

const app = createApp(App);

app.config.globalProperties.metadata = {
  title: "Basic AI",
  shortTitle: "Basic",
  company: "Basic Comm.",
  navSubTitle: "AI",
  url: "www.basicai.online",
  email: "cxsmicguy@gmail.com",
  nation: "India",
  phno: "+91 6282246923",
  copyright: "&copy; 2023 Basic Comm. All right reserved."
}

app.use(router).mount('#app')