<template>
  <div v-if="!loading" class="font-poppins text-white rounded-lg relative">
    <div v-if="!!data.backdrop_path" class="absolute top-0 left-0 w-full">
      <img :src="`https://image.tmdb.org/t/p/original${data.backdrop_path || data.poster_path}`" class="w-full max-h-screen object-cover opacity-70" alt="poster">
      <div class="absolute w-full h-full top-0 left-0 bg-gradient-to-b from-transparent via-transparent to-black pt-12 md:p-24">
      </div>
    </div>
    <div class="pt-18 md:pt-24 z-50">
      <div class="max-w-6xl mx-auto pt-24 p-8 md:p-4  md:mt-[35vh]">
        <div class="info backdrop-blur-none z-50 text-white max-w-xl">
          <div class="pt-4 pb-2">
            <span class="bg-white/40 mb-2 inline-block backdrop-blur-lg rounded-full border border-white border-2 p-1 px-2 text-sm">{{ data.genres[0].name }}</span>
            <h2 class="text-2xl font-bold">{{ data.title || data.name }}</h2>
            <p v-if="data.tagline" class="text-sm font-light text-justify mt-2">{{ data.tagline }}</p>
            <div class="rating flex items-center justify-start gap-2 pt-2">
              <i v-for="star in filledStars()" :key="star" class="bx bxs-star text-orange-400"></i>
              <i v-for="star in outlinedStars()" :key="star" class="bx bx-star text-orange-400"></i>
            </div>
            <div class="details flex text-sm justify-start text-gray-400 pt-2">
              <span>{{ data.release_date }}</span>
            </div>
            <div class="genre pt-2 flex justify-around" id="genre">
              <!-- Add genre tags here -->
            </div>
          </div>
        </div>
        <div v-if="mediaType === 'movie'">
          <Downloader :id="movie" :type="mediaType" />
        </div>
        <div v-else>
          <Trailor :movieId="movie" :mediaType="mediaType" />
        </div>
        <br /><br />

        <h3 class="font-semibold my-6 md:mt-12 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Overview</h3>
        <p class="text-sm font-light text-justify">{{ data.overview || 'Nothing found' }}</p>

        <h2 class="font-semibold mt-6 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Details</h2>
        <table class="table-auto w-full text-white mb-4 mt-3 rounded-xl overflow-hidden">
          <tbody>
            <tr v-if="data.production_companies">
              <td class="font-semibold">Production Companies:</td>
              <td>
                <div class="text-sm text-gray-400 inline-block">
                  <div v-for="(company, index) in data.production_companies" class="my-1 text-white hover:text-purple-400 inline-block mr-2" :key="company.id">
                    <a :href="`/company/` + company.id">{{ company.name }}</a>
                    <span v-if="index < data.production_companies.length - 1">, </span>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="data.homepage">
              <td class="font-semibold">Official Site:</td>
              <td>
                <a :href="data.homepage" target="_blank" class="text-purple-400" rel="noopener noreferrer">Visit Link</a>
              </td>
            </tr>

            <tr v-if="data.spoken_languages">
              <td class="font-semibold">Languages:</td>
              <td>
                <ul class="text-sm text-gray-400 sm:inline-block">
                  <li v-for="(language, index) in data.spoken_languages" class="my-1 text-white inline-block mr-2" :key="language.iso_639_1">
                    {{ language.english_name }}
                    <span v-if="index < data.spoken_languages.length - 1">, </span>
                  </li>
                </ul>
              </td>
            </tr>

            <tr v-if="data.runtime">
              <td class="font-semibold">Runtime:</td>
              <td>
                <p class="text-sm text-gray-400 inline-block">{{ formatRuntime(data.runtime) }}</p>
              </td>
            </tr>

            <tr v-if="data.genres">
              <td class="font-semibold border-b-0 border-gray-700">Genres:</td>
              <td>
                <div class="text-sm text-gray-400 inline-block">
                  <div v-for="(genre, index) in data.genres" :key="genre.id" class="my-1 text-white hover:text-purple-400 inline-block mr-2">
                    <a :href="`/genre/` + genre.id">{{ genre.name }}</a>
                    <span v-if="index < data.genres.length - 1">, </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Banner />

        <div v-if="mediaType === 'tv'">
          <SeriesDownloader :id="movie" :type="mediaType"/>
        </div>

        <Clips :movieId="movie" :mediaType="mediaType" />


        <div v-if="data.credits && data.credits.cast && data.credits.cast.length > 0" class="cast mt-6">
          <h3 class="font-semibold my-6 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Cast</h3>
          <div class="relative group">
            <div @click="scrollShows(-1, 'castS')" class="absolute top-0 left-0 z-50 h-full text-2xl bg-gradient-to-r from-black/70 to-transparent pt-14">
              <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&lt;</span>
            </div>
            <div @click="scrollShows(1, 'castS')" class="absolute top-0 right-0 z-50 h-full text-2xl bg-gradient-to-l from-black/70 to-transparent inline-block pt-14">
              <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&gt;</span>
            </div>
            <div class="castS overflow-x-auto whitespace-nowrap text-white no-scrollbar z-0">
              <div v-for="show in filteredCast.slice(0, 12)" :key="show.id"  class="mr-4 bg-gray-900 hover:bg-gray-700 ease-in ease-out duration-300 p-4 px-8 rounded-xl w-40 inline-flex items-center justify-center ">
                <a :href="`/actor/` + show.id">
                  <img
                    :src="`https://image.tmdb.org/t/p/w200${show.profile_path}`"
                    :alt="show.name || show.title"
                    class="w-24 mx-auto h-24 object-cover rounded-full cursor-pointer opacity-90 hover:opacity-70 transition border-2 border-gray-500"
                  />
                  <div class="px-2 whitespace-normal max-w-24 overflow-hidden text-center">
                    <h2 class="text-md font-semibold mt-2 text-white max-w-24 truncate">{{ show.name.length > 9 ? show.name.substring(0, 7) + ".." : show.name }}</h2>
                    <p class="truncate text-gray-400">{{ show.character.length > 9 ? show.character.substring(0, 7) + ".." : show.character  }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <br>
          <a v-if="data.credits.cast.length > 12" :href="`/${this.mediaType == 'tv' ? 'show' : 'movie'}/` + movie + `/cast`" class="font-bold text-lg flex items-center">Full Cast Details <i class='bx bx-chevron-right text-2xl text-purple-500'></i></a>
        </div>


        <div v-if="data.budget && data.revenue" class="budget-revenue">
          <h3 class="font-semibold my-6 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Box Office</h3>
          <p class="text-md text-gray-400 py-2 pt-0 border-b border-b-gray-600"><span class="text-white font-bold">Budget</span> ${{ data.budget.toLocaleString() }}</p>
          <p class="text-md text-gray-400 py-2 border-b border-b-gray-600"><span class="text-white font-bold">Revenue </span> ${{ data.revenue.toLocaleString() }}</p>
        </div>

        <!-- Render related movies -->
        <div class="max-w-6xl mx-auto text-white select-none">
          <h1 class="font-semibold my-6 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Related</h1>
        </div>
        <Loader :loading="relatedLoading"/>
        <div v-if="relatedMovies && !relatedLoading" class="relative max-w-6xl mx-auto text-white group">
          <div @click="scrollShows(-1, 'rshows')" class="absolute top-0 left-0 z-50 h-full text-2xl bg-gradient-to-r from-black/70 to-transparent pt-24">
            <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&lt;</span>
          </div>
          <div @click="scrollShows(1, 'rshows')" class="absolute top-0 right-0 z-50 h-full text-2xl bg-gradient-to-l from-black/70 to-transparent inline-block pt-24">
            <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&gt;</span>
          </div>
          <div class="rshows overflow-x-auto whitespace-nowrap text-white no-scrollbar z-0 relative">
            <div v-for="show in relatedMovies" :key="show.id"  class="inline-block mr-4 relative">
              <a :href="`/${this.mediaType == 'tv' ? 'show' : 'movie'}/` + show.id" class="group">
                <img
                  :src="`https://image.tmdb.org/t/p/w200${show.poster_path}`"
                  :alt="show.name || show.title"
                  class="w-40 h-60 object-cover rounded-lg cursor-pointer opacity-90 hover:opacity-70 transition"
                />
                <div class="invisible md:group-hover:visible flex items-end absolute p-4 top-0 left-0 z-0 w-full h-full bg-gradient-to-t from-black/80 to-transparent">
                  <h2 class="whitespace-normal text-lg font-bold text-white">
                    {{ show[mediaType === 'tv' ? 'name' : 'title'].length > 35 ? show[mediaType === 'tv' ? 'name' : 'title'].substring(0, 35) + "..." : show[mediaType === 'tv' ? 'name' : 'title'] }}
                  </h2>
                </div>
                <div class="px-2 whitespace-normal max-w-full overflow-hidden md:hidden">
                  <h2 class="text-lg font-semibold mt-2 text-white truncate max-w-[100%]">{{ show[mediaType === 'tv' ? 'name' : 'title'].length > 12 ? show[mediaType === 'tv' ? 'name' : 'title'].substring(0, 12) + "..." : show[mediaType === 'tv' ? 'name' : 'title'] }}</h2>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div v-else class="flex items-center justify-center h-screen">
    <div role="status">
      <svg aria-hidden="true" class="inline w-8 h-8 text-gray-600 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import Trailor from "@/components/Trailor.vue";
import API_KEY from '@/env';
import axios from 'axios';
import { setMetaTags } from '@/metadata';
import Loader from "@/components/Loader.vue";
import Clips from '@/components/ClipsComponent.vue';
import Downloader from "@/components/Downloader.vue";
import SeriesDownloader from "@/components/SeriesDownloader.vue";
import Banner from "@/components/AdComponent.vue";

export default {
  components: {
    Loader,
    Trailor,
    Clips,
    Trailor,
    Downloader,
    SeriesDownloader,
    Banner
  },
  props: ['movie'],
  data() {
    return {
      data: {},
      relatedMovies: [],
      loading: true,
      mediaType: "",
      filteredCast: [],
      scrollStep: 200,
      relatedLoading: true,
    };
  },
  async mounted() {
    try {
      this.mediaType = window.location.pathname.includes('/movie') ? 'movie' : 'tv';

      const response = await axios.get(
        `https://api.themoviedb.org/3/${this.mediaType}/${this.movie}?append_to_response=credits`,
        {
          params: {
            api_key: API_KEY,
          },
        }
      );
      this.data = response.data;
      this.filteredCast = this.data.credits.cast.filter(castMember => castMember.profile_path);

      setMetaTags({
        title: `${this.data.title || this.data.name} - ${ this.metadata.shortTitle }`,
        description: this.data.overview || 'No description available',
      });

      // Fetch related movies
    } catch (error) {
      console.error('Error fetching movie details:', error);
    } finally {
      this.loading = false;

      const relatedResponse = await axios.get(
        `https://api.themoviedb.org/3/discover/${this.mediaType}?api_key=${API_KEY}&with_genres=${this.data.genres[0].id}&with_original_language=${this.data.original_language}`,
        {
          params: {
            api_key: API_KEY,
          },
        }
      );
      
      this.relatedMovies = relatedResponse.data.results.filter((relatedMovie) => {
        return relatedMovie.id != this.movie;
      });

      this.relatedMovies = this.relatedMovies.sort((a, b) => b.popularity - a.popularity);

      this.relatedLoading = false;
    }
  },
  methods: {
    handleWatchTrailer() {
      const trailerKey = this.data.videos?.results?.[0]?.key;
      if (trailerKey) {
        window.open(`https://www.youtube.com/watch?v=${trailerKey}`, '_blank');
      }
    },
    handleResultClick(id) {
      this.$router.push(`/${this.mediaType}/${id}`);
    },
    formatRuntime(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      return `${hours} hours ${remainingMinutes} min`;
    },
    scrollShows(direction, selector) {
      const container = document.querySelector('.' + selector); // Change class name to '.rshows'
      const scrollAmount = this.scrollStep * direction; // Use this.scrollStep
      container.scrollLeft += scrollAmount;
    },
    filledStars() {
      // Convert the rating to a scale of 5
      const converted = Math.round(this.data.vote_average / 2 * 2) / 2;
      return Array.from({ length: Math.round(converted) }, (_, index) => index);
    },
    outlinedStars() {
      const filled = this.filledStars();
      return Array.from({ length: 5 - filled.length }, (_, index) => index);
    },
  },
};
</script>

<style scoped>
/* Add custom styling here if needed */
</style>