<template>
  <div>
    <button v-if="loader" class="animate-pulse bg-gray-300 h-8 w-24 rounded"></button>
    <a v-if="!loader && type === 'movie' && movieData" :href="movieData.dLink" target=”_blank” class="inline-block w-max backdrop-blur-xl bg-white/10 border border-white text-white py-2 px-4 rounded-lg flex items-center">
      <i class='bx bxs-cloud-download mr-2 text-purple-500'></i> Download Now
    </a>
    <div v-else-if="!loader">
      <Trailor :movieId="id" :mediaType="type" />
    </div>
  </div>
</template>

<script>
import Trailor from "@/components/Trailor.vue";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient('https://hcjvlwoloplljaoeikih.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhjanZsd29sb3BsbGphb2Vpa2loIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQxMTUyNTAsImV4cCI6MjAxOTY5MTI1MH0.de6v9sNKkoKC0l3ldgUzWBBMs2bFuPLAO6hRgEO-OXE');

export default {
  components: {
    Trailor
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      movieData: null,
      loader: true,
    };
  },
  async mounted() {
    if (this.type === 'movie') {
      // Assuming you have set up Supabase client
      const { data, error } = await supabase
        .from('movies')
        .select('*')
        .eq('id', this.id)
        .single();

      if (error) {
        console.error('Error fetching movie data:', error.message);
      } else {
        this.movieData = JSON.parse(data.data);
        console.log(typeof this.movieData)
      }

      this.loader = false;
    }
  },
};
</script>

<style scoped>
/* Add your component styles here if needed */
</style>
