<template>
  <div class="p-8 max-w-6xl mx-auto text-white">
    <h1 class="text-2xl font-bold">Japanse Animation</h1>
  </div>
  <Loader :loading="loading"/>
  <div v-if="animatedTVShows && !loading" class="relative max-w-6xl mx-auto text-white group">
    <div @click="scrollShows(-1)" class="absolute top-0 left-0 z-50 h-full text-2xl bg-gradient-to-r from-black/70 to-transparent pt-24">
      <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&lt;</span>
    </div>
    <div @click="scrollShows(1)" class="absolute top-0 right-0 z-50 h-full text-2xl bg-gradient-to-l from-black/70 to-transparent inline-block pt-24">
      <span class="invisible md:group-hover:visible bg-black/40 select-none backdrop-blur-md p-2 rounded-full border border-gray-600">&gt;</span>
    </div>
    <div class="shows overflow-x-auto whitespace-nowrap text-white no-scrollbar z-0 relative">
      <div v-for="(show, index) in animatedTVShows" :key="show.id" class="inline-block mr-4 relative group">
        <a :href="`/show/` + show.id">
          <img
            :src="getImageUrl(show.poster_path)"
            :alt="show.name"
            class="w-40 h-60 object-cover rounded-lg cursor-pointer opacity-90 hover:opacity-70 transition"
            @click="redirectToShowDetails(show.id)"
          />
          <div class="invisible md:group-hover:visible flex items-end absolute p-4 top-0 left-0 z-0 w-full h-full bg-gradient-to-t from-black/80 to-transparent">
            <h2 class="whitespace-normal text-lg font-bold text-white">
              {{ show.name.length > 35 ? show.name.substring(0, 35) + "..." : show.name }}
            </h2>
          </div>
          <div class="px-2 whitespace-normal max-w-full overflow-hidden md:hidden">
            <h2 class="text-lg font-semibold mt-2 text-white truncate max-w-[100%]">{{ show.name.length > 12 ? show.name.substring(0, 12) + "..." : show.name }}</h2>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import API_KEY from '@/env';
import Loader from "./Loader.vue"

export default {
  components: {
    Loader
  },
  data() {
    return {
      animatedTVShows: [],
      apiKey: API_KEY,
      scrollStep: 200,
      loading: true,
    };
  },
  mounted() {
    this.fetchAnimatedTVShows();
  },
  methods: {
    async fetchAnimatedTVShows() {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/discover/tv?api_key=${this.apiKey}&with_genres=16&with_original_language=ja`
        );

        if (response.data && response.data.results) {
          this.animatedTVShows = response.data.results.slice(0, 10); // Display the first 10 shows
          this.loading = false;
        }
      } catch (error) {
        console.error("Error fetching animated TV shows:", error);
      }
    },
    getImageUrl(posterPath) {
      const baseUrl = "https://image.tmdb.org/t/p/w200";
      return posterPath ? `${baseUrl}${posterPath}` : "";
    },
    redirectToShowDetails(showId) {
      console.log(`Redirect to TV show details for ID: ${showId}`);
      // Implement navigation to show details page or other actions
    },
    scrollShows(direction) {
      const container = document.querySelector('.shows');
      const scrollAmount = this.scrollStep * direction;
      container.scrollLeft += scrollAmount;
    }, 
  },
};
</script>
