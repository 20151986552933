<template>
  <div v-if="!loading" class="font-poppins text-white rounded-lg">
    <div v-if="!!data.backdrop_path" class="absolute top-0 left-0 w-full">
      <img :src="`https://image.tmdb.org/t/p/original${data.backdrop_path}`" class="w-full object-cover opacity-70" alt="poster">
      <div class="absolute w-full h-full top-0 left-0 bg-gradient-to-b from-transparent via-transparent to-black pt-12 md:p-24">
      </div>
    </div>
    <div class="pt-12 md:pt-24 z-50">
      <div class="max-w-4xl mx-auto mt-24 p-8 md:p-4">
        <div class="info grid gap-4 border border-1 border-gray-600 rounded-xl p-6 bg-black/30 backdrop-blur-md text-white w-full md:w-1/2 mx-auto">
          <template v-if="data.poster_path">
            <img
              :src="`https://image.tmdb.org/t/p/w500${data.poster_path}`"
              alt="poster"
              class="mx-auto rounded-md w-min max-w-full max-h-1/2"
            />
          </template>
          <template v-else>
            <img
              src="/error-bg.webp"
              alt="poster"
              class="mx-auto rounded-md max-w-full max-h-1/2"
            />
          </template>
          <div>
            <h2 class="text-2xl font-bold text-center">{{ data.title || data.name }}</h2>
            <div class="rating flex items-center justify-center gap-2 mt-2">
              <i class="bx bx-star text-orange-400"></i>
              <h4>{{ data.vote_average }}</h4>
            </div>
            <div class="details flex text-sm justify-center text-gray-400 mt-2">
              <span class="mx-4">{{ data.vote_count }}</span>
              <span>{{ data.release_date }}</span>
            </div>
            <div class="genre mt-2 flex justify-around" id="genre">
              <!-- Add genre tags here -->
            </div>
          </div>
        </div>
        <br /><br />
        <h3 class="font-semibold mt-6">{{ this.metadata.shortTitle }}:</h3>
        <p class="text-sm font-light text-justify">{{ data.overview || 'Nothing found' }}</p>
        <template v-if="data.videos">
          <div class="mt-2">
            <h3 class="font-bold">Trailer</h3>
            <button @click="handleWatchTrailer" class="border border-white rounded-xl p-2 px-4 hover:bg-white hover:bg-opacity-20 mt-2">
              Watch Trailer
            </button>
          </div>
        </template>

        <Banner />

        <!-- Render related movies -->
        <div v-if="relatedMovies && relatedMovies.length > 0">
          <h3 class="font-semibold mt-6 mb-6">WatchNow (recomendations):</h3>
          <ul class="grid grid-cols-2 md:grid-cols-4 gap-4 text-black">
            <li v-for="movie in relatedMovies" :key="movie.id" class="mb-12">
              <a :href="`/watchnow/movie/` + movie.id">
                <img
                  :src="`https://image.tmdb.org/t/p/w300${movie.poster_path}`"
                  :alt="movie.title"
                  class="w-full h-full rounded-md mb-2"
                  width="300"
                  height="400"
                />
                <div class="px-2">
                  <h2 class="text-lg font-semibold mt-2 text-white truncate">{{ movie.title || movie.name }}</h2>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex items-center justify-center h-screen">
    <div role="status">
      <svg aria-hidden="true" class="inline w-8 h-8 text-gray-600 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import API_KEY from '@/env';
import axios from 'axios';
import { setMetaTags } from '@/metadata';
import Banner from "@/components/AdComponent.vue";

export default {
  props: ['movie'],
  components: {
    Banner
  },
  data() {
    return {
      data: {},
      relatedMovies: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      // Fetch details of the main movie
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${this.movie}`,
        {
          params: {
            api_key: API_KEY,
          },
        }
      );
      this.data = response.data;

      setMetaTags({
        title: `${this.data.title || this.data.name} Related Movies - ${ this.metadata.shortTitle }`,
        description: this.data.overview || 'No description available',
      });

      const genreIds = this.data.genres.map(genre => genre.id).join(',')

      // Fetch related movies
      const relatedResponse = await axios.get(
        `https://api.themoviedb.org/3/discover/movie?sort_by=popularity.desc&vote_average.gte=7&with_genres=${genreIds}&with_original_language=${this.data.original_language}`,
        {
          params: {
            api_key: API_KEY,
          },
        }
      );

      const relatedResponse2 = await axios.get(
        `https://api.themoviedb.org/3/discover/tv?sort_by=popularity.desc&vote_average.gte=7&with_genres=${genreIds}&with_original_language=${this.data.original_language}`,
        {
          params: {
            api_key: API_KEY,
          },
        }
      );
      
      const filter1 = relatedResponse.data.results.filter((relatedMovie) => {
        return relatedMovie.id != this.movie;
      });

      const filter2 = relatedResponse2.data.results.filter((relatedMovie) => {
        return relatedMovie.id != this.movie;
      });

      this.relatedMovies = [...filter1, ...filter2];

      this.relatedMovies = this.relatedMovies.sort((a, b) => b.popularity - a.popularity);
    } catch (error) {
      console.error('Error fetching movie details:', error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleWatchTrailer() {
      const trailerKey = this.data.videos?.results?.[0]?.key;
      if (trailerKey) {
        window.open(`https://www.youtube.com/watch?v=${trailerKey}`, '_blank');
      }
    },
    handleResultClick(id) {
      this.$router.push(`/movie/${id}`);
    },
  },
};
</script>

<style scoped>
/* Add custom styling here if needed */
</style>