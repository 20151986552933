<!-- ClipsComponent.vue -->

<template>
  <div v-if="videosData && videosData.results && videosData.results.length > 0">
    <h3 class="font-semibold my-6 border-s-2 border-s-purple-600 text-xl md:text-2xl pl-2">Clips</h3>
    <p class="text-sm font-light text-justify mb-4">Youtube Videos for this movie</p>

    <div v-if="videoLoader" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div class="bg-gradient-to-b from-gray-700 via-gray-900 to-gray-800 rounded-xl w-full h-[40vh] max-h-[1080px]" v-for="n in 4" :key="n"></div>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div v-if="videosData && videosData.results && videosData.results.length > 0" v-for="(video, index) in videosData.results.slice(0, 4)" :key="index" class="relative w-full overflow-hidden rounded-md">
        <div v-if="!videoLoader">
          <!-- You may need to customize this based on the actual structure of TMDb video data -->
          <iframe :src="getYouTubeEmbedUrl(video.key)" class="w-full h-[40vh]" frameborder="0" allowfullscreen @load="handleVideoLoad"></iframe>
        </div>
        <div v-else class="skeleton-loader w-full h-[40vh]"></div>
        <h3 class="text-sm p-2 font-semibold">{{ video.name }}</h3>
      </div>
    </div>

    <div v-if="videosData && videosData.results && videosData.results.length === 0">
      <p>No videos available.</p>
    </div>

    <a v-if="videosData && videosData.results && videosData.results.length > 4"  :href="`${this.$route.path.replace(/\/$/, '')}/clips`" class="font-bold text-lg backdrop-blur-none flex items-center mt-4">
      Full Videos <i class='bx bx-chevron-right text-2xl text-purple-500'></i>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import API_KEY from '@/env'; // Replace with your TMDb API key

export default {
  props: {
    movieId: {
      type: String,
      required: true,
    },
    mediaType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      videosData: {},
      videoLoader: true,
    };
  },
  async mounted() {
    try {
      const videoResponses = await this.searchTMDbVideos(this.movieId, this.mediaType);

      this.videosData = videoResponses.data;
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      this.videoLoader = false;
    }
  },
  methods: {
    async searchTMDbVideos(id, type) {
      try {
        const videosResponse = await axios.get(
          `https://api.themoviedb.org/3/${type}/${id}/videos`,
          {
            params: {
              api_key: API_KEY,
            },
          }
        );

        return videosResponse;
      } catch (error) {
        console.error('Error searching TMDb videos:', error);
        throw error;
      }
    },

    getYouTubeEmbedUrl(videoKey) {
      return `https://www.youtube.com/embed/${videoKey}`;
    },

    handleVideoLoad() {
      this.videoLoader = false;
    },
  },
};
</script>